<script setup lang="ts">
// import http from '~/utils/http/ufetch'
import { autoRegisterApi } from '~/api/user'
import { getHomeVideoList } from '~/api/home'

const res1 = ref()

async function get1() {
  try {
    await getHomeVideoList({ pageNum: 1, pageSize: 4 })
    const { data, error } = await autoRegisterApi({
      deviceNo: '9633b65e13f107896207db1167fa5374',
      registerReference: 'http://172.21.251.43:9002'
    })
    if (error.value) {
      console.log(error.value)
    } else {
      res1.value = data.value
    }
  } catch (error) {}
}
async function get2() {
  const res = await useFetch('/api/user/info', {
    retry: 3,
    baseURL: '/',
    // baseURL: import.meta.env.VITE_APP_BASE_API,
    onRequest() {
      console.log('onRequest')
    },
    onRequestError() {
      console.log('onRequestError')
    },
    onResponse({ response, error }) {
      console.log('onResponse', response, error)
    },
    onResponseError({ response, error }) {
      console.log('onResponseError', response, error)
    }
  })
  console.log(res)
}

await get1()
</script>

<template>
  {{ res1 }}
  <button type="button" @click="get1">
    get1
  </button>
  <button type="button" @click="get2">
    get2
  </button>
</template>
